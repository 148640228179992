import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { registerUser } from "../../redux/actions/register";
import { message } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import logo from "../../assets/images/logo.png";
import ok_frame2 from "../../assets/images/ok_frame2.png";
import { Image } from "antd";
import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import classes from "./Register.module.css";
import { inputs } from "./registerForm";
import { HiArrowLeft } from "react-icons/hi2";

const Register: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(null);
  const [fullName, setFullName] = useState(null);
  const [agreement, setAgreement] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (location.state?.formData) {
      const { fullName, email, password, agreement } = location.state.formData;
      console.log("Restoring form data from:", location.pathname, location.state.formData);
      setFullName(fullName || null);
      setEmail(email || "");
      setPassword(password || null);
      setAgreement(agreement || false);
      form.setFieldsValue({
        name: fullName,
        email: email,
        password: password,
        confirm: password,
        agreement: agreement,
      });
    }
  }, [location.state, form]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      console.log("Enter pressed");
      registerUsers(event);
    }
  };

  const registerUsers = (e) => {
    form
      .validateFields()
      .then((values) => {
        if (values.requiredField) {
          return;
        }
        if (!agreement) {
          console.log("check agreements");
          return;
        }
        const payload = { fullName, email, password };
        dispatch(registerUser(payload))
          .then(() => {
            setAgreement(false);
            setEmail("");
            setFullName(null);
            setPassword(null);
            message.success("Ju lutemi shikoni e-mailin tuaj");
            navigate("/login");
          })
          .catch((error) => {});
      })
      .catch((error) => {
        console.error("Validation failed:", error);
      });
  };

  const checkBoxOnChange = (e) => {
    const isChecked = e && e.target ? e.target.checked : false;
    setAgreement(isChecked);
  };

  const navigateToTerms = () => {
    console.log("Navigating to Terms with data:", { fullName, email, password, agreement });
    navigate("/terms-of-use", {
      state: {
        formData: { fullName, email, password, agreement },
        from: "/register",
      },
    });
  };

  const navigateToPrivacy = () => {
    console.log("Navigating to Privacy with data:", { fullName, email, password, agreement });
    navigate("/privacy-policy", {
      state: {
        formData: { fullName, email, password, agreement },
        from: "/register",
      },
    });
  };

  return (
    <div className={classes.mainDiv}>
      <Row>
        <Col span={24} className={classes.backButton}>
          <div className={classes.circleButton} onClick={() => navigate("/lajmet")}>
            <HiArrowLeft fontSize="1.2rem" color="white" />
          </div>
          <div className={classes.backTitle} onClick={() => navigate("/lajmet")}>
            <h2>Rikthehu ne faqe</h2>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={14} className={classes.lefLogoColumn}>
          <div className={classes.logoDiv}>
            <div
              style={{
                width: "70%",
                height: "70%",
                position: "relative",
                backgroundImage: `url(${ok_frame2})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
              className={classes.logoTextDiv}
            >
              <Image
                style={{ width: "5rem", height: "5rem", position: "relative", zIndex: 2 }}
                preview={false}
                src={logo}
              />
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 1,
                }}
              ></div>
              <h2>ONLINE KOSOVA</h2>
              <h3>Burim i besueshëm i informacionit për të gjithë shqiptarët</h3>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={10}>
          <div className={classes.registerDiv}>
            <div className={classes.registerTitle}>
              <h1>Krijoni llogarinë tuaj</h1>
              <h4>Vendosni detajet më poshtë</h4>
            </div>
            <Form form={form} name="register" onFinish={registerUsers} style={{ width: "70%" }} scrollToFirstError>
              <Form.Item name="name" className={classes.styledInput} style={{ width: "100%" }} {...inputs.name}>
                <Input placeholder="Emri juaj i plote" onChange={(e) => setFullName(e.target.value)} />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                className={classes.styledInput}
                {...inputs.email}
                style={{ width: "100%" }}
              >
                <Input placeholder="Vendosni email-in tuaj" onChange={(e) => setEmail(e.target.value)} />
              </Form.Item>
              <Form.Item
                name="password"
                label="Fjalekalimi"
                className={classes.styledInput}
                {...inputs.password}
                style={{ width: "100%" }}
                hasFeedback
              >
                <Input.Password
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Vendos fjalekalimin tuaj"
                />
              </Form.Item>
              <Form.Item
                className={classes.styledInput}
                name="confirm"
                label="Konfirmoni Fjalekalimin"
                {...inputs.confirmPassword}
                dependencies={["password"]}
                style={{ width: "100%", resize: "none" }}
                hasFeedback
              >
                <Input.Password
                  size="large"
                  style={{ resize: "none" }}
                  placeholder="Ju lutemi konfirmojeni fjalekalimin tuaj"
                />
              </Form.Item>
              <Form.Item
                name="agreement"
                valuePropName="checked"
                className={classes.termsConditions}
                {...inputs.agreement}
              >
                <Checkbox onChange={checkBoxOnChange} style={{ color: "#FFFFFF" }}>
                  I have read the{" "}
                  <a style={{ color: "#40A2DE" }} onClick={navigateToTerms}>
                    Terms of use
                  </a>{" "}
                  &{" "}
                  <a style={{ color: "#40A2DE" }} onClick={navigateToPrivacy}>
                    Privacy Policy
                  </a>
                </Checkbox>
              </Form.Item>
              <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{ width: "100%" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  onKeyDown={handleKeyDown}
                  onClick={registerUsers}
                >
                  Register
                </Button>
              </Form.Item>
            </Form>
            <div className={classes.footerRegister}>
              <p>
                Keni llogari? <Link to="/login" style={{ color: "#40A2DE" }}>Kyquni</Link>
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Register;