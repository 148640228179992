import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from '../SelectSources/SelectSources.module.css';
import logo from "../../assets/images/logo.png";
import { HiArrowLeft } from "react-icons/hi2";
import { Card, Col, Row } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { selectSourceData } from "../../redux/slices/sourceSlice";
import { AppDispatch } from "../../redux/store";
import { fetchSource } from "../../redux/actions/sourcesActions";
import { selectAuthenticated } from "../../redux/slices/authSlice";
import { loginAnonymously } from '../../redux/actions/auth';
import { Select } from 'antd-v5';

const SelectState: React.FC = () => {
    const isAuthenticated = useSelector(selectAuthenticated);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const sourcesData = useSelector(selectSourceData);
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const [selectedCards, setSelectedCards] = useState<string[]>(localStorage.getItem('selectedSources') ? JSON.parse(localStorage.getItem('selectedSources')) : []);
    const [country, setCountry] = useState<string>(localStorage.getItem('country') || ''); // Retrieve from localStorage

    useEffect(() => {
        const fetchData = () => {
            dispatch(fetchSource()).then(() => { });
        };

        isAuthenticated
            ? fetchData()
            : dispatch(loginAnonymously())
                .then(fetchData)
                .catch((error) => {
                    console.error('Anonymous login failed:', error);
                });
    }, [isAuthenticated]);

    useEffect(() => {
        const lsAllSelected = localStorage.getItem('allSelected');
        setSelectAll(lsAllSelected === 'true');
    }, []);

    const onContinueHandler = () => {
        const payloadSourceString = selectedCards.join(',');
        const selectAllToString = JSON.stringify(selectAll);

        localStorage.setItem("selectedSources", JSON.stringify(selectedCards));
        localStorage.setItem('sourceIds', payloadSourceString);
        localStorage.setItem('allSelected', selectAllToString);
        localStorage.setItem('country', country);

        navigate('/lajmet');
    };

    const handleChange = (value: string) => {
        setCountry(value);
        localStorage.setItem('country', value); // Save the selected country to localStorage
        console.log(`selected ${value}`);
    };

    return (
        <div className={classes.mainDiv1}>
            <Row>
                <Col span={24} className={classes.headerDiv}>
                    <div className={classes.backTitle} onClick={() => navigate("/lajmet")}>
                        <div className={classes.circleButton} onClick={() => navigate("/lajmet")}>
                            <HiArrowLeft fontSize='1.2rem' color='white' />
                        </div>
                        <h2>Rikthehuni ne faqe</h2>
                    </div>
                    <div className={classes.headerLogo}>
                        <h2>Online</h2>
                        <img src={logo} alt="logoImage" />
                        <h2>Kosova</h2>
                    </div>
                    <div style={{ width: '33%' }}></div>
                </Col>
            </Row>
            <Row className={classes.centerDiv}>
                <Col span={18}>
                    <div className={classes.centerDivHeader}>
                        <h2>Top Shtetet</h2>
                        <h3>Selektro burimin e lajmeve.</h3>
                    </div>
                </Col>
                <Col span={6}>
                    <Select
                        value={country || "Perzgjidhni shtetin"} // Use the state value or the default text
                        dropdownStyle={{ borderRadius: "0px" }}
                        className={classes.selectDropdown}
                        onChange={handleChange}
                        options={[
                            {
                                value: 'KS',
                                label: (
                                    <div className={classes.customOption}>
                                        <img src="https://cdn.britannica.com/18/114418-050-E12B5707/Flag-Kosovo.jpg" alt="Kosova" />
                                        Lajme nga Kosova
                                    </div>
                                )
                            },
                            {
                                value: 'AL',
                                label: (
                                    <div className={classes.customOption}>
                                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRC3oC2fAPycTMRBwivt_59S07mU48t--9m8Q&s" alt="Shqiperia" />
                                        Lajme nga Shqipëria
                                    </div>
                                )
                            },
                        ]}
                    />
                </Col>
            </Row>
            <Row className={classes.confirmSection}>
                <Col span={6}>
                    <Card
                        onClick={() => onContinueHandler()}
                        className={classes.confirmCard}
                        bodyStyle={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            padding: '10px',
                        }}
                    >
                        <div className={classes.confirmButton}>
                            <h4 style={{ color: 'white' }}>Vazhdoni</h4>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default SelectState;
