import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login/Login";
import Posts from "./pages/Posts/Posts";
import Layouts from "./components/Layout/Layout";
import LandingPage from "./Landing/Landing";
import Sources from "./pages/Sources/Sources";
import Categories from "./pages/Categories/Categories";
import ADSlist from "./adsPages/Ads/ADSlist";
import PrivateRoutes from "./PrivateRoutes/PrivateRoutes";
import AdsLayouts from "./components/AdsLayout/AdsLayout";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";
import NewAD from "./CreateAD/NewAD";
import PrivateRoutesUser from "./PrivateRoutes/PrivateRoutesUser";
import Register from "./pages/Register/Register";
import Users from "./pages/Users/Users";
import AdsPanel from "./pages/AdsPanel/AdsPanel";
import RedirectUrl from "./pages/RedirectUrl/RedirectUrl";
import News from "./pages/news/News";
import Termsofuse from "./Landing/sections/Footer/Termsofuse";
import Privacypolicy from "./Landing/sections/Footer/Privacypolicy";
import Faq from "./Landing/sections/Footer/Faq";
import EditProfile from "./pages/EditProfile/EditProfile";
import SelectSources from "./pages/SelectSources/SelectSources";
import SearchNews from "./pages/SearchNews/SearchNews";
import SavedNews from "./pages/SavedNews/SavedNews";
import SelectState from "./pages/SelectState/SelectState";
import AlbaniaPosts from "./pages/AlbaniaAdminPanel/AlbaniaPosts";
import AlbaniaSources from "./pages/AlbaniaAdminPanel/AlbaniaSources";
import AlbanianCategories from "./pages/AlbaniaAdminPanel/AlbanianCategories";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<LandingPage />} />
      <Route exact path="/lajmet" element={<News />} />
      <Route exact path="/terms-of-use" element={<Termsofuse />} />
      <Route exact path="/privacy-policy" element={<Privacypolicy />} />
      <Route exact path="/faq" element={<Faq />} />
      <Route path="login" element={<Login />} />
      <Route path="password/reset" element={<ResetPassword />} />
      <Route path="password/forgot" element={<ForgotPassword />} />
      <Route path="verify" element={<VerifyEmail />} />
      <Route path="register" element={<Register />} />
      <Route path="selectsources" element={<SelectSources />} />
      <Route path="selectstate" element={<SelectState />} />
      <Route path="search" element={<SearchNews />} />
      <Route path="navigate" element={<RedirectUrl />} />
      <Route path="savedNews" element={<SavedNews />} />

      <Route element={<PrivateRoutes />}>
        <Route element={<Layouts />}>
          <Route path="posts" element={<Posts />} />
          <Route path="categories" element={<Categories />} />
          <Route path="sources" element={<Sources />} />
          <Route path="adspanel" element={<AdsPanel />} />
          <Route path="users" element={<Users />} />
          <Route path="albanianposts" element={<AlbaniaPosts />} />
          <Route path="albaniansources" element={<AlbaniaSources />} />
          <Route path="albaniancategories" element={<AlbanianCategories />} />
        </Route>
      </Route>
      <Route element={<PrivateRoutesUser />}>
        <Route element={<AdsLayouts />}>
          <Route path="adslist" element={<ADSlist />} />
        </Route>
        <Route path="newad" element={<NewAD />} />
        <Route path="editprofile" element={<EditProfile />} />
      </Route>
    </Routes>
  );
}

export default App;
