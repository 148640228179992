import "./Intro.css";
import logo from "../../../assets/images/logo.png";
import DownloadButtons from "../../../components/DownloadButton/DownloadButtons";
import phone from "../../../assets/images/Phone.png";
import { FaArrowRight } from "react-icons/fa";
import {useNavigate} from "react-router-dom";

const Intro = () => {
  const navigate = useNavigate();
  const handlewebLink = () => {
    navigate('/lajmet');
  };

  return (
    <section className="intro-section">
      <div className="intro-left-section">
        <div id="logo-section">
          <img src={logo} alt="logo" />
          <h2>ONLINE KOSOVA</h2>
        </div>

        <h1 id="intro-title">
          Trendet dhe <br />
          Informacioni në{" "}
          <span className="yellowText">
            Fokus <br />
          </span>{" "}
        </h1>
        <p>
          <b>
            Shkarkoni <span className="yellowText">Aplikacionin</span>
          </b>
        </p>
        <DownloadButtons />
        <h3 id="web-link" onClick={handlewebLink}>
          Vizitoni web aplikacionin →
        </h3>
      </div>
      <div className="intro-right-section">
        <img src={phone} alt="phoneImage" />
      </div>
    </section>
  );
};

export default Intro;
