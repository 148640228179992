import "./Advertisement.css";
import adsImage from '../../../assets/images/Picture1.png';
import chart from '../../../assets/images/progress chart.png';
import { Link } from 'react-router-dom';

const Advertisement = () => {
    return ( 
        <div className="advertisement-section">
            <div className="advertisementTitle">
                <h1>Ekspozo biznesin dhe sherbimet permes online kosova.</h1>
            </div>
            <div className="advertisementImages">
                <p>Me ne, bizneset mund të krijojnë kampanja të personalizuara të marketingut dhe të publikojnë reklamat e tyre në kohë reale, duke rritur ndjeshem shanset e tyre per ekspozim ne audience me te gjere.</p>
                <button className="ads-button" onClick={() => window.location.href = '/adslist'}>Reklamo me ne!</button>
            </div>
        </div>
     );
}
 
export default Advertisement;