import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import classes from './SavedNews.module.css'
import logo from "../../assets/images/logo.png";
import {HiArrowLeft} from "react-icons/hi2";
import {Col, Divider, Row, Image} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../redux/store";
import {RxHamburgerMenu} from "react-icons/rx";
import SideDrawer from "../../components/NewsPage/SideDrawer";
import {selectAuthenticated} from "../../redux/slices/authSlice";
import {addSavedNews, fetchSavedNews, removeSavedNews} from "../../redux/actions/newsActions";
import {selectSaveNewsData} from "../../redux/slices/newsSlice";
import InfiniteScroll from 'react-infinite-scroll-component';
import NewsModal from "../../components/Modal/NewsModal/NewsModal";
import {FaBookmark, FaRegBookmark} from "react-icons/fa";
import noImage from "../../assets/images/noNewsImage.png";

const SavedNews: React.FC = () => {

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const savedNewsData = useSelector(selectSaveNewsData);
    const [data, setData] = useState(savedNewsData.data.content || [])
    useEffect(() => {
        dispatch(fetchSavedNews(0)).then(() => {
           })
    }, [])

    useEffect(() => {
        if (savedNewsData.data.content) {
            setData(savedNewsData.data.content);
        }
    }, [savedNewsData.data.content]);

    const isAuthenticated = useSelector(selectAuthenticated);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [pagination, setPagination] = useState({
        current: 0,
        pageSize: 10,
        total: savedNewsData.data.totalElements,
    });
    const showDrawer = () => {
        setOpenDrawer(true);
    };
    const onCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const fetchData = (page) => {
        setLoading(true);
        setTimeout(() => {
            dispatch(fetchSavedNews(page)).then(() => {
                setData([...savedNewsData.data.content]);
                setLoading(false);
            })
            if (data.length >= pagination.total) {
                setHasMore(false);
            }
        }, 1000);
    };

    const fetchMoreData = () => {
        const nextPage = pagination.current + 1;
        setPagination({...pagination, current: nextPage});
        fetchData(nextPage);
    };

    function convertTimestampToHoursAgo(timestamp: string): string {
        const parseTimestamp = new Date(timestamp);
        const currentTime = new Date();
        const timeDifferenceInMilliseconds = currentTime.getTime() - parseTimestamp.getTime();
        const timeDifferenceInSeconds = timeDifferenceInMilliseconds / 1000;
        const timeDifferenceInMinutes = timeDifferenceInSeconds / 60;
        const minutesAgo = Math.floor(timeDifferenceInMinutes);
        if (minutesAgo < 60) {
            if (minutesAgo < 1) {
                return `just now`;
            }
            return `${minutesAgo} ${minutesAgo === 1 ? 'minut' : 'minuta'} ago`;
        }
        const hoursAgo = Math.floor(timeDifferenceInMinutes / 60);
        if (hoursAgo < 24) {
            return `${minutesAgo} ${minutesAgo === 1 ? 'minut' : 'minuta'} më parë`;
        }
        const daysAgo = Math.floor(timeDifferenceInMinutes / (60 * 24));
        return `${daysAgo} ${daysAgo === 1 ? 'ditë' : 'ditë'} më parë`;
    }

    const [currentNew, setCurrentNew] = useState(null);
    const [showNewsModal, setShowNewsModal] = useState(false);

    const showNewsModalFunction = (currentNews) => {
        setCurrentNew(currentNews)
        setShowNewsModal(true);
    };

    const [favoriteStatus, setFavoriteStatus] = useState({});

    useEffect(() => {
        const initialFavoriteStatus = {};
        data.forEach(newsItem => {
            initialFavoriteStatus[newsItem.id] = newsItem.isFavorite || false;
        });
        setFavoriteStatus(initialFavoriteStatus);
    }, [data]);

    const handleBookmarkToggle = (currentNews) => {
        const updatedFavoriteStatus = { ...favoriteStatus };
        updatedFavoriteStatus[currentNews.id] = !updatedFavoriteStatus[currentNews.id];
        setFavoriteStatus(updatedFavoriteStatus);

        if (updatedFavoriteStatus[currentNews.id]) {
            dispatch(addSavedNews(currentNews.id));
        } else {
            dispatch(removeSavedNews(currentNews.id));
        }
    };

    return (
        <div className={classes.mainDiv}>
            <SideDrawer isOpen={openDrawer} onClose={onCloseDrawer} isAuthenticated={isAuthenticated}/>
            <NewsModal  showModal={showNewsModal} activeNews={currentNew} setShowModal={setShowNewsModal}/>
            <Row>
                <Col span={24} className={classes.headerDiv}>
                    <div className={classes.backTitle} onClick={() => navigate("/lajmet")}>
                        <div className={classes.circleButton} onClick={() => navigate("/lajmet")}>
                            <HiArrowLeft
                                fontSize='1.2rem'
                                color='white'
                            />
                        </div>
                    </div>
                    <div className={classes.headerLogo}>
                        <h2>Online</h2>
                        <img src={logo} alt="logoImage"/>
                        <h2>Kosova</h2>
                    </div>
                    <div className={classes.headerRight}>
                        <RxHamburgerMenu
                            className={classes.styledHamburgerMenu}
                            style={{
                                color: 'white',
                                fontSize: '1.5rem'
                            }}
                            onClick={showDrawer}
                        />
                    </div>
                </Col>
            </Row>
                <div className={classes.title}>
                    <h2>Lajmet e ruajtura</h2>
                </div>
            <Row className={classes.infiniteScrollRow}>
                <div className={classes.infiniteScrollDiv}
                     style={{width: '50rem', height: '70vh', overflow: 'auto', alignSelf: 'center'}}
                     id='scrollableDiv'>
                    <InfiniteScroll
                        dataLength={data.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<div
                            style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px', color: 'white'}}>
                            {data.length< 1 ?  'NUK KA TE DHENA' : ''}</div>}
                        endMessage={<Divider plain style={{color: 'white'}}>Nuk ka me shume lajme te ruajtura!</Divider>}
                        scrollableTarget="scrollableDiv"
                        style={{overflow: 'hidden'}}
                        className={classes.scrollContainer}
                    >
                        <Row gutter={[8, 16]} justify="center" align="middle">
                            {data?.map((item, index) => (
                                <Col key={index} xs={22} sm={22} md={22} lg={24}>
                                    <div className={classes.styledCard} >
                                        <div className={classes.CardLeftDiv} onClick={() => showNewsModalFunction(item)}>
                                            <Image
                                                preview={false}
                                                className={classes.mainImage}
                                                src={item.imageUrl}
                                                style={{objectFit: 'contain', width: '100%', height: '100%'}}
                                                fallback={noImage}
                                            />
                                        </div>
                                        <div className={classes.cardText} onClick={() => showNewsModalFunction(item)}>
                                            <div className={classes.categoryText}>
                                                <h4>#{item.categories[0]?.name} </h4>
                                                <h5>{convertTimestampToHoursAgo(item.publishedDate)}</h5>
                                            </div>
                                            <h2>{item.title.length <= 150 ? item.title : item.title.slice(0, 150) + '...'}</h2>
                                            <h3>{item.source.name}</h3>
                                        </div>
                                        <div  className={classes.saveButton} >
                                            {favoriteStatus[item.id] ? (
                                                <FaBookmark fontSize={'1.2rem'} color={'#285ADC'}  className={classes.isFavoriteIcon} onClick={() => handleBookmarkToggle(item)}/>
                                            ) : (
                                                <FaRegBookmark  fontSize={'1.2rem'} color={'white'}  className={classes.isNotFavoriteIcon} onClick={() => handleBookmarkToggle(item)}/>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            ))
                            }
                        </Row>
                    </InfiniteScroll>
                </div>
            </Row>
        </div>
    );
}

export default SavedNews;