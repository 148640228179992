import "./WebSection.css";
import webimg from "../../../assets/images/webimg.png";
import { FaArrowRight } from "react-icons/fa";
import {useNavigate} from "react-router-dom";

const WebSection = () => {
    const navigate = useNavigate();
    const handlewebLink = () => {
      navigate('/lajmet');
  };

  return (
    <div className="web-section">
      <h1 id="web-title">
        Online Kosova mund ta ndiqni edhe në <br />
        faqen tonë të<span className="yellowText"> internetit.</span>
      </h1>
      <p>
        Tani ju mund të lexoni lajmet edhe nga web aplikacioni.
        <br />
        Ju mund të gjeni lidhjen më poshtë.
      </p>
      <button className="webBtn">
        <h1 onClick={handlewebLink}>Website</h1>
        <li>
          <FaArrowRight />
        </li>
      </button>
      <img src={webimg} alt="Image" />
    </div>
  );
};

export default WebSection;
