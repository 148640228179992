import React, {useEffect, useState} from 'react'
import { useNavigate} from "react-router-dom";
import classes from './EditProfile.module.css'
import logo from '../../assets/images/logo.png'
import {Menu, Image} from 'antd';
import EditName from '../../components/EditName/EditName'
import ChangePassword from '../../components/ChangePassword/ChangePassword'
import DeactivateProfile from "../../components/DeactivateProfile/DeactivateProfile";
import {IoIosCloseCircleOutline} from "react-icons/io";
import {Row,
} from 'antd';
import {setDeactivateError, setPasswordError} from "../../redux/slices/authSlice";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../redux/store";

const EditProfile: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(setDeactivateError(null))
        dispatch(setPasswordError(null))
    }, []);

    const handleMenuClick = (e) => {
        setSelectedMenuItem(e.key);
    };
    const navigate = useNavigate();

    const renderContent = () => {
        switch (selectedMenuItem) {
            case 'name':
                return <div style={{padding:24}}><EditName/></div>;
            case 'password':
                return <div style={{ padding: 24}}><ChangePassword/></div>;
            case 'deactivate':
                return <div style={{ padding: 24}}><DeactivateProfile/></div>;
            default:
                return null;
        }
    };


    const [selectedMenuItem, setSelectedMenuItem] = useState('name');

    return (
        <div>
            <Row>
                    <div className={classes.mainWrapper}>
                        <div className={classes.logoHeader}>
                            <div className={classes.rightSide}>
                                <IoIosCloseCircleOutline className={classes.rightSideIcon} style={{fontSize: '30px', color: 'white', float: 'left'}}  onClick={() => {
                                    navigate('/lajmet');
                                }} />
                            </div>
                            <Image preview={false} style={{width: '3rem', height: '3rem'}} src={logo} />
                        </div>
                        <div className={classes.mainTitle}>
                            <h2>EDITONI PROFILIN</h2>
                        </div>
                        <div className={classes.mainDiv}>
                            <div className={classes.menuDiv}>
                                <Menu
                                    className={classes.menuStyle}
                                    theme="light"
                                    mode="horizontal"
                                    defaultSelectedKeys={['name']}
                                    selectedKeys={[selectedMenuItem]}
                                    onClick={handleMenuClick}
                                >
                                    <Menu.Item key="name" >
                                        Emrin Tuaj
                                    </Menu.Item>
                                    <Menu.Item key="password" >
                                        Fjalekalimin
                                    </Menu.Item>
                                    <Menu.Item key="deactivate" >
                                        Deaktivizo profilin
                                    </Menu.Item>
                                </Menu>
                            </div>
                            <div className={classes.content}>
                                {renderContent()}
                            </div>
                        </div>
                    </div>
            </Row>
        </div>


    );
};

export default EditProfile;