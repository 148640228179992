import React, {useEffect, useState, useRef} from "react";
import {useNavigate} from "react-router-dom";
import classes from './SearchNews.module.css'
import logo from "../../assets/images/logo.png";
import {HiArrowLeft} from "react-icons/hi2";
import {Col, Divider, Image, Input, Row, Spin} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../redux/store";
import {RxHamburgerMenu} from "react-icons/rx";
import SideDrawer from "../../components/NewsPage/SideDrawer";
import {selectAuthenticated} from "../../redux/slices/authSlice";
import {searchNews} from "../../redux/actions/newsActions";
import {
    selectCurrentSearchPage, selectSearchNewsData,
    selectSearchValue,
    setSearchValue
} from "../../redux/slices/newsSlice";
import InfiniteScroll from 'react-infinite-scroll-component';
import NewsModal from "../../components/Modal/NewsModal/NewsModal";
import noImage from "../../assets/images/noNewsImage.png";

const SearchNews: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const {Search} = Input;
    const isAuthenticated = useSelector(selectAuthenticated);
    const [openDrawer, setOpenDrawer] = useState(false);
    const currentPage = useSelector(selectCurrentSearchPage);
    const searchValue = useSelector(selectSearchValue);
    const searchData = useSelector(selectSearchNewsData);
    const [search, setSearch] = useState(searchValue);

    const scrollableDivRef = useRef<HTMLDivElement>(null);

    const [data, setData] = useState(searchData.data.content || []);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: searchData.data.totalElements,
    });
    const [placeholder, setPlaceholder] = useState('Kerko lajmin');
    const onFocus = () => {
        setPlaceholder(''); // Clear the placeholder when the input is focused
    };

    const onBlur = () => {
        if (!search) {
            setPlaceholder('Kerko lajmin'); // Restore the placeholder if the input is empty
        }
    };

    const showDrawer = () => {
        setOpenDrawer(true);
    };

    const onCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const handleClear = () => {
        setSearch(null);
        dispatch(setSearchValue(null))
    }

    const handleInputChange = async (e) => {

        const {value} = e.target;

        if (!value) {
            handleClear();
            setData([]);
        } else {
            setSearch(value);
            if (value.toString().trim().length >= 3) {
                dispatch(setSearchValue(value));
                await dispatch(searchNews(0, value)).then((res) => {
                    setData(res.content)
                    if (scrollableDivRef.current) {
                        scrollableDivRef.current.scrollTo(0, 0);
                    }
                })
            } else {
                setData([]);
            }
        }
    }

    const handleSearch = async (query) => {
        setSearch(query);

        if (query.toString().trim().length >= 3) {
                dispatch(setSearchValue(query));
            await dispatch(searchNews(0, query)).then((res) => {
                setData(res.content)
                if (scrollableDivRef.current) {
                    scrollableDivRef.current.scrollTo(0, 0);
                }
            })
        }
    }

    useEffect(() => {
        dispatch(searchNews(currentPage, search)).then(res => {
            setData(res.content);
        })
    }, []);

    const fetchData = (page, pageSize) => {
        setLoading(true);

        setTimeout(() => {

            dispatch(searchNews(page, searchValue)).then((res) => {
                setData([...data, ...res.content]);
                setLoading(false);
            })

            if (data.length >= pagination.total) {
                setHasMore(false);
            }
        }, 1000);
    };


    const fetchMoreData = () => {
        const nextPage = pagination.current + 1;
        setPagination({...pagination, current: nextPage});
        fetchData(nextPage, pagination.pageSize);
    };


    function convertTimestampToHoursAgo(timestamp: string): string {
        const parseTimestamp = new Date(timestamp);
        const currentTime = new Date();

        const timeDifferenceInMilliseconds = currentTime.getTime() - parseTimestamp.getTime();
        const timeDifferenceInSeconds = timeDifferenceInMilliseconds / 1000;
        const timeDifferenceInMinutes = timeDifferenceInSeconds / 60;

        const minutesAgo = Math.floor(timeDifferenceInMinutes);
        if (minutesAgo < 60) {
            if (minutesAgo < 1) {
                return `just now`;
            }
            return `${minutesAgo} ${minutesAgo === 1 ? 'min' : 'mins'} ago`;
        }

        const hoursAgo = Math.floor(timeDifferenceInMinutes / 60);
        if (hoursAgo < 24) {
            return `${hoursAgo} ${hoursAgo === 1 ? 'hour' : 'hours'} ago`;
        }

        const daysAgo = Math.floor(timeDifferenceInMinutes / (60 * 24));
        return `${daysAgo} ${daysAgo === 1 ? 'day' : 'days'} ago`;
    }

    const [currentNew, setCurrentNew] = useState(null);
    const [showNewsModal, setShowNewsModal] = useState(false);

    const showNewsModalFunction = (currentNews) => {
        setCurrentNew(currentNews)
        setShowNewsModal(true);
    };

    return (
        <div className={classes.mainDiv}>
            <SideDrawer isOpen={openDrawer} onClose={onCloseDrawer} isAuthenticated={isAuthenticated}/>
            <NewsModal  showModal={showNewsModal} activeNews={currentNew} setShowModal={setShowNewsModal}/>
            <Row>
                <Col span={24} className={classes.headerDiv}>
                    <div className={classes.backTitle} onClick={() => navigate("/lajmet")}>
                        <div className={classes.circleButton} onClick={() => navigate("/lajmet")}>
                            <HiArrowLeft
                                fontSize='1.2rem'
                                color='white'
                            />
                        </div>
                        <h2>Rikthehuni ne faqe</h2>
                    </div>
                    <div className={classes.headerLogo}>
                        <h2>Online</h2>
                        <img src={logo} alt="logoImage"/>
                        <h2>Kosova</h2>
                    </div>
                    <div className={classes.headerRight}>
                        <RxHamburgerMenu
                            className={classes.styledHamburgerMenu}
                            style={{
                                color: 'white',
                                fontSize: '1.5rem'
                            }}
                            onClick={showDrawer}
                        />
                    </div>
                </Col>
            </Row>
            <Row className={classes.searchDiv}>
                <Col span={10}>
                    <Search
                        className={classes.styledSearchInput}
                        value={search}
                        placeholder={placeholder}
                        onSearch={handleSearch}
                        onChange={handleInputChange}
                        style={{width: '100%'}}
                        enterButton
                        onFocus={onFocus}
                        onBlur={onBlur}/>
                </Col>
            </Row>
            <Row className={classes.infiniteScrollRow}>
                <div className={classes.infiniteScrollDiv}
                     style={{width: '50rem', height: '70vh', overflow: 'auto', alignSelf: 'center'}}
                     ref={scrollableDivRef}
                     id='scrollableDiv'>
                    <InfiniteScroll
                        dataLength={data.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={<div
                            style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px', color: 'white'}}>
                            {data.length<1 ?  'NUK KA TE DHENA' : <Spin/> }</div>}
                        endMessage={<Divider plain style={{color: 'white'}}>It is all, nothing more 🤐</Divider>}
                        scrollableTarget="scrollableDiv"
                        style={{overflow: 'hidden'}}
                        className={classes.scrollContainer}
                    >
                        <Row gutter={[16, 16]}>
                            {data?.map((item, index) => (
                                <Col key={index} xs={24} sm={24} md={24} lg={24}>
                                    <div className={classes.styledCard} onClick={() => showNewsModalFunction(item)}>
                                        <div className={classes.CardLeftDiv}>
                                            <Image
                                                preview={false}
                                                className={classes.mainImage}
                                                src={item.imageUrl}
                                                style={{objectFit: 'contain', width: '100%', height: '100%'}}
                                                fallback={noImage}
                                            />
                                        </div>
                                        <div className={classes.cardText}>
                                            <div className={classes.categoryText}>
                                                <h4>#{item.categories[0]?.name} </h4>
                                                <h5>{convertTimestampToHoursAgo(item.publishedDate)}</h5>
                                            </div>
                                            <h2>{item.title.length <= 150 ? item.title : item.title.slice(0, 150) + '...'}</h2>
                                            <h3>{item.source.name}</h3>
                                        </div>
                                    </div>
                                </Col>
                            ))
                            }
                        </Row>
                    </InfiniteScroll>
                </div>
            </Row>
        </div>
    );
}

export default SearchNews;